html {
	font-family: "Comfortaa", "Helvetica", "Arial", sans-serif;
	--fugaz-font-family: "Fugaz One", "Helvetica", "Arial", sans-serif;
}

h1,
h2,
h3,
h4,
b,
strong,
button,
.button {
	font-family: "Fugaz One", "Helvetica", "Arial", sans-serif;
	font-weight: normal !important;
	line-height: 1.1;
	text-transform: uppercase;
}

body {
	overflow-y: scroll;
	/* overscroll-behavior-y: none;  allow android to pull to refreash */
	font-size: 14px;
	font-weight: 400;
	line-height: 1.43;
	color: var(--default-text);
	background-color: var(--background);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	--ios-nav-bar-height: 48px;
}

body::backdrop {
	background-color: var(--background);
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

h1 {
	font-size: 2em;
}
h2 {
	font-size: 1.5em;
}
h3 {
	font-size: 1.17em;
}
h4 {
	font-size: 1em;
}
h5 {
	font-size: 0.83em;
}
h6 {
	font-size: 0.67em;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

pre {
	white-space: pre-wrap;
}

img {
	width: 100%;
}

a {
	color: var(--primary-color);
}

a:not(:hover) {
	text-decoration: none;
}

a.clickable {
	color: inherit;
	text-decoration: none;
}

table {
	width: 100%;
	border-collapse: collapse;
}

thead > tr,
tr:not(:last-child) {
	border-bottom: 1px solid var(--default-border);
}

td {
	padding: 6px 24px;
}

td > svg {
	vertical-align: middle;
}

hr {
	margin: 18px 6px 12px;
	height: 1px;
	background-color: var(--default-border);
	border: none;
}

.hide {
	display: none;
}

.subtitle {
	font-family: "Fugaz One", "Helvetica", "Arial", sans-serif;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	text-transform: uppercase;
	color: #e8e8e8;
}

main > .circular-loader {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 20px;
}


